



const Header = () => {

    return (
        <header>
            <h1>TodoList</h1>
        </header>
    )
}

export default Header;